<template>
  <Header title="Villa List" />
  <section class="b_booking_section top_margin">
    <div class="b_booking_row row_padding main_container">
      <div class="b_booking_col">
        <div class="b_booking_title">
          <p class="body_heading">Accommodations</p>
        </div>
        <div class="b_booking_block">
          <div class="b_booking_box">
            <div class="b_booking_lt">
              <div class="b_booking_inr_lt">
                <div class="b_booking_img_slider">
                  <div class="b_booking_slider_for">
                    <Carousel
                      :items-to-show="1"
                      :wrap-around="false"
                      v-model="villa_sliders.platinum.currentSlide"
                      :transition="500"
                    >
                      <Slide
                        v-for="(image, index) in villa_sliders.platinum.images"
                        :key="index"
                      >
                        <div class="b_booking_for_imag">
                          <a
                            class="b_booking_for_img"
                            :href="image.src"
                            data-lightbox="models"
                          >
                            <img :src="image.src" />
                          </a>
                        </div>
                      </Slide>
                    </Carousel>
                  </div>
                  <div class="b_booking_slider_nav">
                    <Carousel
                      :items-to-show="5"
                      :wrap-around="true"
                      v-model="villa_sliders.platinum.currentSlide"
                      ref="carousel_platinum"
                      :autoplay="2000"
                      :transition="500"
                    >
                      <Slide
                        v-for="(image, index) in villa_sliders.platinum.images"
                        :key="index"
                      >
                        <div class="b_booking_nav_imag">
                          <div
                            class="b_booking_nav_img"
                            @click="
                              slideTo(index + 1 - 1, villa_sliders.platinum)
                            "
                          >
                            <img :src="image.src" />
                          </div>
                        </div>
                      </Slide>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
            <div class="b_booking_rt">
              <div class="b_booking_inr_rt">
                <div class="b_booking_rt_box_1">
                  <div class="b_booking_rt_titie">
                    <p class="body_heading">
                      {{
                        villaDetail.platinum
                          ? villaDetail.platinum.villa_name
                          : "Platinum Villa"
                      }}
                    </p>
                  </div>
                  <div class="b_booking_text">
                    <p class="data_sub_heading">
                      Destination. Luxury. Respite.
                    </p>
                  </div>
                  <div class="b_booking_tax font_12">
                    <div class="main_qun_li">
                      <p>
                        Max Quantity per
                        <i class="fa fa-home" aria-hidden="true"></i> 01 X
                        <i class="fa fa-user" aria-hidden="true"></i>
                        {{
                          villaDetail.platinum
                            ? villaDetail.platinum.allow_adult
                            : "0"
                        }}
                      </p>
                    </div>
                    <div class="main_qun_li">
                      <p>
                        Extra Person
                        <i class="fa fa-home" aria-hidden="true"></i> 01 X
                        <i class="fa fa-user" aria-hidden="true"></i>
                        {{
                          villaDetail.platinum
                            ? villaDetail.platinum.max_adult_limit -
                              villaDetail.platinum.allow_adult
                            : "0"
                        }}
                      </p>
                    </div>
                    <div
                      class="main_qun_li"
                      v-show="
                        villaDetail.platinum
                          ? form.total_adult > villaDetail.platinum.allow_adult
                            ? true
                            : false
                          : false
                      "
                    >
                      <!-- 7 > 6 -->
                      <p>
                        Extra Per Person Rs.&nbsp;
                        <i class="fa fa-user" aria-hidden="true"></i
                        >{{
                          villaDetail.platinum
                            ? villaDetail.platinum.extra_per_person_charge
                            : "0"
                        }}
                      </p>
                    </div>
                  </div>

                  <div class="b_booking_feture">
                    <!-- b_booking_feture -->
                    <ul class="main_qun__ul">
                      <li class="main_qun_li">
                        <img :src="first_img" alt="" class="main_qun_img" />
                        <span>1 Master bedroom</span>
                      </li>
                      <li class="main_qun_li">
                        <img :src="second_img" alt="" class="main_qun_img" />
                        <span>2 Primary Bedroom</span>
                      </li>
                      <li class="main_qun_li">
                        <img :src="three_img" alt="" class="main_qun_img" />
                        <span>Drawing Room</span>
                      </li>
                      <li class="main_qun_li">
                        <img :src="four_img" alt="" class="main_qun_img" />
                        <span>Living Room</span>
                      </li>
                      <li class="main_qun_li">
                        <img :src="five_img" alt="" class="main_qun_img" />
                        <span>Dining Aera</span>
                      </li>
                      <li class="main_qun_li">
                        <img :src="six_img" alt="" class="main_qun_img" />
                        <span>Personal Lawn</span>
                      </li>
                      <li class="main_qun_li">
                        <img :src="seven_img" alt="" class="main_qun_img" />
                        <span>Own Parking</span>
                      </li>
                    </ul>
                  </div>
                  <div class="b_booking_view">
                    <router-link class="b_booking_a_view" to="/platinum-villa">
                      <span>View Vila</span>
                    </router-link>
                  </div>
                </div>
                <div class="b_booking_rt_box_2">
                  <div class="b_booking_rating">
                    <div class="b_booking_rat">
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <span>4.9</span>
                    </div>
                    <div class="b_booking_all_review font_12">
                      <span>12 Reviews</span>
                    </div>
                  </div>
                  <div class="b_booking_person font_12">
                    <span>{{ dayCount }} days | </span>
                    <span>{{ form.total_adult }} adults | </span>
                    <span>{{ form.total_child }} child</span>
                  </div>
                  <div class="b_booking_tax font_12">
                    <span
                      >+Rs.
                      {{
                        villaDetail.platinum ? villaDetail.platinum.gst : "0"
                      }}
                      taxes and
                      {{
                        villaDetail.platinum
                          ? villaDetail.platinum.extra_total_charge
                          : "0"
                      }}
                      Extra Person charges</span
                    >
                  </div>
                  <div class="b_booking_payment">
                    <span
                      >Rs.
                      {{
                        villaDetail.platinum
                          ? villaDetail.platinum.total_price
                          : "0"
                      }}</span
                    >
                  </div>
                  <button
                    type="button"
                    class="b_booking_Reserve site_button"
                    @click="reserveVilla"
                    :disabled="
                      villaDetail.platinum ? !villaDetail.platinum.slot : true
                    "
                  >
                    <span>Reserve</span>
                  </button>
                  <div class="b_booking_login">
                    <a href="#" class="b_booking_login_a font_12" v-if="token">
                      <span>More detalis</span>
                    </a>
                    <a href="#" class="b_booking_login_a font_12" v-else>
                      <span>Login for more detalis</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="b_booking_box">
            <div class="b_booking_lt">
              <div class="b_booking_inr_lt">
                <div class="b_booking_img_slider">
                  <div class="b_booking_slider_for">
                    <Carousel
                      :items-to-show="1"
                      :wrap-around="false"
                      v-model="villa_sliders.diamond.currentSlide"
                      :transition="500"
                    >
                      <Slide
                        v-for="(image, index) in villa_sliders.diamond.images"
                        :key="index"
                      >
                        <div class="b_booking_for_imag">
                          <a
                            class="b_booking_for_img"
                            :href="image.src"
                            data-lightbox="models"
                          >
                            <img :src="image.src" />
                          </a>
                        </div>
                      </Slide>
                    </Carousel>
                  </div>
                  <div class="b_booking_slider_nav">
                    <Carousel
                      :items-to-show="5"
                      :wrap-around="true"
                      v-model="villa_sliders.diamond.currentSlide"
                      ref="carousel_diamond"
                      :autoplay="2000"
                      :transition="500"
                    >
                      <Slide
                        v-for="(image, index) in villa_sliders.diamond.images"
                        :key="index"
                      >
                        <div class="b_booking_nav_imag">
                          <div
                            class="b_booking_nav_img"
                            @click="
                              slideTo(index + 1 - 1, villa_sliders.diamond)
                            "
                          >
                            <img :src="image.src" />
                          </div>
                        </div>
                      </Slide>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
            <div class="b_booking_rt">
              <div class="b_booking_inr_rt">
                <div class="b_booking_rt_box_1">
                  <div class="b_booking_rt_titie">
                    <p class="body_heading">
                      {{
                        villaDetail.diamond
                          ? villaDetail.diamond.villa_name
                          : "Diamond Villa"
                      }}
                    </p>
                  </div>
                  <div class="b_booking_text">
                    <p class="data_sub_heading">
                      Enjoy luxury like never before!
                    </p>
                  </div>

                  <div class="b_booking_tax font_12">
                    <div class="main_qun_li">
                      <p>
                        Max Quantity per
                        <i class="fa fa-home" aria-hidden="true"></i>
                        01 X
                        <i class="fa fa-user" aria-hidden="true"></i>
                        {{
                          villaDetail.diamond
                            ? villaDetail.diamond.allow_adult
                            : "0"
                        }}
                      </p>
                    </div>
                    <div class="main_qun_li">
                      <p>
                        Extra Person
                        <i class="fa fa-home" aria-hidden="true"></i>01 X
                        <i class="fa fa-user" aria-hidden="true"></i>
                        {{
                          villaDetail.diamond
                            ? villaDetail.diamond.max_adult_limit -
                              villaDetail.diamond.allow_adult
                            : "0"
                        }}
                      </p>
                    </div>
                    <div
                      class="main_qun_li"
                      v-show="
                        villaDetail.diamond
                          ? form.total_adult > villaDetail.diamond.allow_adult
                            ? true
                            : false
                          : false
                      "
                    >
                      <!-- 7 > 6 -->
                      <p>
                        Extra Per Person Rs.&nbsp;
                        <i class="fa fa-user" aria-hidden="true"></i>
                        {{
                          villaDetail.diamond
                            ? villaDetail.diamond.extra_per_person_charge
                            : "0"
                        }}
                      </p>
                    </div>
                  </div>

                  <div class="b_booking_feture">
                    <ul class="main_qun__ul">
                      <li class="main_qun_li">
                        <img :src="first_img" alt="" class="main_qun_img" />
                        <span>1 Master bedroom</span>
                      </li>
                      <li class="main_qun_li">
                        <img :src="second_img" alt="" class="main_qun_img" />
                        <span>2 Primary Bedroom</span>
                      </li>
                      <li class="main_qun_li">
                        <img :src="three_img" alt="" class="main_qun_img" />
                        <span>Drawing Room</span>
                      </li>
                      <li class="main_qun_li">
                        <img :src="four_img" alt="" class="main_qun_img" />
                        <span>Living Room</span>
                      </li>
                      <li class="main_qun_li">
                        <img :src="five_img" alt="" class="main_qun_img" />
                        <span>Dining Aera</span>
                      </li>
                      <li class="main_qun_li">
                        <img :src="six_img" alt="" class="main_qun_img" />
                        <span>Personal Lawn</span>
                      </li>
                      <li class="main_qun_li">
                        <img :src="seven_img" alt="" class="main_qun_img" />
                        <span>Own Parking</span>
                      </li>
                    </ul>
                  </div>
                  <div class="b_booking_view">
                    <router-link class="b_booking_a_view" to="/diamond-villa">
                      <span>View Vila</span>
                    </router-link>
                  </div>
                </div>
                <div class="b_booking_rt_box_2">
                  <div class="b_booking_rating">
                    <div class="b_booking_rat">
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <span>4.9</span>
                    </div>
                    <div class="b_booking_all_review font_12">
                      <span>12 Reviews</span>
                    </div>
                  </div>
                  <div class="b_booking_person font_12">
                    <span>{{ dayCount }} days | </span>
                    <span>{{ form.total_adult }} adults | </span>
                    <span>{{ form.total_child }} child</span>
                  </div>
                  <div class="b_booking_tax font_12">
                    <span
                      >+Rs.
                      {{
                        villaDetail.diamond ? villaDetail.diamond.gst : "0"
                      }}
                      taxes and
                      {{
                        villaDetail.diamond
                          ? villaDetail.diamond.extra_total_charge
                          : "0"
                      }}
                      Extra Person charges</span
                    >
                  </div>
                  <div class="b_booking_payment">
                    <span
                      >Rs.
                      {{
                        villaDetail.diamond
                          ? villaDetail.diamond.total_price
                          : "0"
                      }}</span
                    >
                  </div>
                  <button
                    type="button"
                    class="b_booking_Reserve site_button"
                    @click="reserveVilla"
                    :disabled="
                      villaDetail.diamond ? !villaDetail.diamond.slot : true
                    "
                  >
                    <span>Reserve</span>
                  </button>
                  <div class="b_booking_login">
                    <a href="#" class="b_booking_login_a font_12">
                      <span>Login for more detalis</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="b_booking_box">
            <div class="b_booking_lt">
              <div class="b_booking_inr_lt">
                <div class="b_booking_img_slider">
                  <div class="b_booking_slider_for">
                    <Carousel
                      :items-to-show="1"
                      :wrap-around="false"
                      v-model="villa_sliders.cottages.currentSlide"
                      :transition="500"
                    >
                      <Slide
                        v-for="(image, index) in villa_sliders.cottages.images"
                        :key="index"
                      >
                        <div class="b_booking_for_imag">
                          <a
                            class="b_booking_for_img"
                            :href="image.src"
                            data-lightbox="models"
                          >
                            <img :src="image.src" />
                          </a>
                        </div>
                      </Slide>
                    </Carousel>
                  </div>
                  <div class="b_booking_slider_nav">
                    <Carousel
                      :items-to-show="5"
                      :wrap-around="true"
                      v-model="villa_sliders.cottages.currentSlide"
                      ref="carousel_cottages"
                      :autoplay="2000"
                      :transition="500"
                    >
                      <Slide
                        v-for="(image, index) in villa_sliders.cottages.images"
                        :key="index"
                      >
                        <div class="b_booking_nav_imag">
                          <div
                            class="b_booking_nav_img"
                            @click="
                              slideTo(index + 1 - 1, villa_sliders.cottages)
                            "
                          >
                            <img :src="image.src" />
                          </div>
                        </div>
                      </Slide>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
            <div class="b_booking_rt">
              <div class="b_booking_inr_rt">
                <div class="b_booking_rt_box_1">
                  <div class="b_booking_rt_titie">
                    <p class="body_heading">
                      {{
                        villaDetail.cottage
                          ? villaDetail.cottage.villa_name
                          : "Cottage Villa"
                      }}
                    </p>
                  </div>
                  <div class="b_booking_text">
                    <p class="data_sub_heading">Vacation Time’s a-calling!</p>
                  </div>
                  <div class="b_booking_tax font_12">
                    <div class="main_qun_li">
                      <p>
                        Max Quantity per
                        <i class="fa fa-home" aria-hidden="true"></i> 01 X
                        <i class="fa fa-user" aria-hidden="true"></i>
                        {{
                          villaDetail.cottage
                            ? villaDetail.cottage.allow_adult
                            : "0"
                        }}
                      </p>
                    </div>
                    <div class="main_qun_li">
                      <p>
                        Extra Person
                        <i class="fa fa-home" aria-hidden="true"></i> 01 X
                        <i class="fa fa-user" aria-hidden="true"></i>
                        {{
                          villaDetail.cottage
                            ? villaDetail.cottage.max_adult_limit -
                              villaDetail.cottage.allow_adult
                            : "0"
                        }}
                      </p>
                    </div>
                    <div
                      class="main_qun_li"
                      v-show="
                        villaDetail.cottage
                          ? form.total_adult > villaDetail.cottage.allow_adult
                            ? true
                            : false
                          : false
                      "
                    >
                      <!-- 7 > 6 -->
                      <p>
                        Extra Per Person Rs.&nbsp;
                        <i class="fa fa-user" aria-hidden="true"></i>
                        {{
                          villaDetail.cottage
                            ? villaDetail.cottage.extra_per_person_charge
                            : "0"
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="b_booking_feture">
                    <ul class="main_qun__ul">
                      <li class="main_qun_li">
                        <img :src="first_img" alt="" class="main_qun_img" />
                        <span>Master bedroom</span>
                      </li>
                      <li class="main_qun_li">
                        <img :src="saf" alt="" class="main_qun_img" />
                        <span>Safety Locker</span>
                      </li>
                      <li class="main_qun_li">
                        <img :src="war" alt="" class="main_qun_img" />
                        <span>Own Wardrobe</span>
                      </li>
                    </ul>
                  </div>
                  <div class="b_booking_view">
                    <!-- <a href="#" class="b_booking_a_view"> -->
                    <router-link class="b_booking_a_view" to="/cottage">
                      <span>View Vila</span>
                    </router-link>
                    <!-- </a> -->
                  </div>
                </div>
                <div class="b_booking_rt_box_2">
                  <div class="b_booking_rating">
                    <div class="b_booking_rat">
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <span>4.9</span>
                    </div>
                    <div class="b_booking_all_review font_12">
                      <span>12 Reviews</span>
                    </div>
                  </div>
                  <div class="b_booking_person font_12">
                    <span>{{ dayCount }} days | </span>
                    <span>{{ form.total_adult }} adults | </span>
                    <span>{{ form.total_child }} child</span>
                  </div>
                  <div class="b_booking_tax font_12">
                    <span
                      >+Rs.
                      {{
                        villaDetail.cottage ? villaDetail.cottage.gst : "0"
                      }}
                      taxes and
                      {{
                        villaDetail.cottage
                          ? villaDetail.cottage.extra_total_charge
                          : "0"
                      }}
                      Extra Person charges</span
                    >
                  </div>
                  <div class="b_booking_payment">
                    <span
                      >Rs.
                      {{
                        villaDetail.cottage
                          ? villaDetail.cottage.total_price
                          : "0"
                      }}</span
                    >
                  </div>
                  <button
                    type="button"
                    class="b_booking_Reserve site_button"
                    @click="reserveVilla"
                    :disabled="
                      villaDetail.cottage ? !villaDetail.cottage.slot : true
                    "
                  >
                    <span>Reserve</span>
                  </button>
                  <div class="b_booking_login">
                    <a href="#" class="b_booking_login_a font_12">
                      <span>Login for more detalis</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
  <Login :popupMobile="popupMobile" @update="onUpdate($event)" />
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Login from "../components/LoginPopup.vue";
import villa_01 from "../assets/images/inner_page/platinum_villa/G1.jpg";
import villa_02 from "../assets/images/inner_page/platinum_villa/G2.jpg";
import villa_03 from "../assets/images/inner_page/platinum_villa/G3.jpg";
import villa_04 from "../assets/images/inner_page/platinum_villa/G4.jpg";
import villa_05 from "../assets/images/inner_page/platinum_villa/G5.jpg";
import villa_06 from "../assets/images/inner_page/platinum_villa/G6.jpg";
import villa_07 from "../assets/images/inner_page/platinum_villa/G7.jpg";
import villa_08 from "../assets/images/inner_page/platinum_villa/G8.jpg";
import first_img from "../assets/images/User/first_img.png";
import second_img from "../assets/images/User/2bed.png";
import three_img from "../assets/images/User/drawing.png";
import four_img from "../assets/images/User/five_img.png";
import five_img from "../assets/images/User/dining.png";
import six_img from "../assets/images/User/four_img.png";
import seven_img from "../assets/images/User/parking.png";
import saf from "../assets/images/User/saf.png";
import war from "../assets/images/User/war.png";
import structure1 from "../assets/images/gallery/structure/01-structure.jpg";
import villa2 from "../assets/images/inner_page/diamond_villa/D1.jpg";
import villa3 from "../assets/images/inner_page/diamond_villa/D2.jpg";
import villa4 from "../assets/images/inner_page/diamond_villa/D3.jpg";
import villa5 from "../assets/images/inner_page/diamond_villa/D4.jpg";
import villa6 from "../assets/images/inner_page/diamond_villa/D5.jpg";
import villa7 from "../assets/images/inner_page/cottages/C1.jpg";
import villa8 from "../assets/images/inner_page/cottages/C2.jpg";
import villa9 from "../assets/images/inner_page/cottages/C3.jpg";
import villa10 from "../assets/images/inner_page/cottages/C4.jpg";
import villa11 from "../assets/images/inner_page/cottages/C5.jpg";
import Form from "vform";
import axios from "axios";
import { isProxy, toRaw } from "vue";
import { mapGetters, mapActions } from "vuex";

import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "VillaList",
  components: {
    Header,
    Footer,
    Login,
    Carousel,
    Slide,
  },
  data() {
    return {
      villa_sliders: {
        platinum: {
          currentSlide: 0,
          images: [
            {
              src: require("../assets/images/inner_page/platinum_villa/G1.jpg"),
            },
            {
              src: require("../assets/images/inner_page/platinum_villa/G2.jpg"),
            },
            {
              src: require("../assets/images/inner_page/platinum_villa/G3.jpg"),
            },
            {
              src: require("../assets/images/inner_page/platinum_villa/G4.jpg"),
            },
            {
              src: require("../assets/images/inner_page/platinum_villa/G5.jpg"),
            },
          ],
        },
        diamond: {
          currentSlide: 0,
          images: [
            {
              src: require("../assets/images/inner_page/diamond_villa/D1.jpg"),
            },
            {
              src: require("../assets/images/inner_page/diamond_villa/D2.jpg"),
            },
            {
              src: require("../assets/images/inner_page/diamond_villa/D3.jpg"),
            },
            {
              src: require("../assets/images/inner_page/diamond_villa/D4.jpg"),
            },
            {
              src: require("../assets/images/inner_page/diamond_villa/D5.jpg"),
            },
          ],
        },
        cottages: {
          currentSlide: 0,
          images: [
            {
              src: require("../assets/images/inner_page/cottages/C1.jpg"),
            },
            {
              src: require("../assets/images/inner_page/cottages/C2.jpg"),
            },
            {
              src: require("../assets/images/inner_page/cottages/C3.jpg"),
            },
            {
              src: require("../assets/images/inner_page/cottages/C4.jpg"),
            },
            {
              src: require("../assets/images/inner_page/cottages/C5.jpg"),
            },
          ],
        },
      },
      villa_01: villa_01,
      villa_02: villa_02,
      villa_03: villa_03,
      villa_04: villa_04,
      villa_05: villa_05,
      villa_06: villa_06,
      villa_07: villa_07,
      villa_08: villa_08,
      first_img: first_img,
      second_img: second_img,
      three_img: three_img,
      four_img: four_img,
      five_img: five_img,
      six_img: six_img,
      seven_img: seven_img,
      saf: saf,
      war: war,
      structure1: structure1,
      villa2: villa2,
      villa3: villa3,
      villa4: villa4,
      villa5: villa5,
      villa6: villa6,
      villa7: villa7,
      villa8: villa8,
      villa9: villa9,
      villa10: villa10,
      villa11: villa11,
      villaList: [],
      dayCount: "",
      form: new Form({
        id: "",
        check_in_date: sessionStorage.getItem("check_in_date"),
        check_out_date: sessionStorage.getItem("check_out_date"),
        total_adult: sessionStorage.getItem("total_adult"),
        total_child: sessionStorage.getItem("total_child"),
        qty: sessionStorage.getItem("qty"),
        booking: {
          total: 0,
          gst_tax: 0,
          extra_person_charge: 0,
          festival_charge: 0,
          gst_tax_percent: 0,
        },
      }),
      villaDetail: [],
      token: localStorage.getItem("auth_cus_token"),
      popupMobile: false,
    };
  },
  mounted() {
    let recaptchaScript2 = document.createElement("script");
    recaptchaScript2.setAttribute("src", "./js/slick/slick.js");
    document.head.appendChild(recaptchaScript2);
    let recaptchaScript3 = document.createElement("script");
    recaptchaScript3.setAttribute("src", "./js/slick/slick.min.js");
    document.head.appendChild(recaptchaScript3);
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "./js/script.js");
    document.head.appendChild(recaptchaScript);

    window.scrollTo({ top: 0, behavior: "smooth" });

    this.getVillaDetail();

    this.daysSinceByDate();
  },
  methods: {
    onUpdate(updatedData) {
      this.popupMobile = updatedData;
    },
    slideTo(val, object) {
      object.currentSlide = val;
    },
    async getVillaDetail() {
      this.loader = true;
      axios
        .post(
          process.env.VUE_APP_API_CUS_URL + "/search-villa",
          {
            check_in_date: this.form.check_in_date,
            check_out_date: this.form.check_out_date,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // "Authorization": `Bearer ${this.token}`
            },
          }
        )
        .then((response) => {
          this.villaList = response.data.data;

          for (let x in this.villaList) {
            if (
              this.villaList[x].villa_name.toLowerCase() == "platinum villa"
            ) {
              this.villaDetail.platinum = this.villaList[x];
              var weekendPriceP = this.villaDetail.platinum.weekend
                ? this.villaDetail.platinum.weekend_price
                : 0;
              var festivalChargeP = this.villaDetail.platinum.festivalCharge
                ? this.villaDetail.platinum.festivalCharge
                : 0;
              if (
                this.form.total_adult > this.villaDetail.platinum.allow_adult
              ) {
                var total_p_extra_person =
                  this.form.total_adult - this.villaDetail.platinum.allow_adult; // 8-6=2
                this.villaDetail.platinum.extra_total_charge =
                  total_p_extra_person *
                  this.villaDetail.platinum.extra_per_person_charge;
                this.villaDetail.platinum.total_price =
                  this.villaDetail.platinum.price * this.form.qty +
                  this.villaDetail.platinum.extra_total_charge +
                  festivalChargeP +
                  weekendPriceP;
              } else {
                this.villaDetail.platinum.total_price =
                  this.villaDetail.platinum.price * this.form.qty +
                  festivalChargeP +
                  weekendPriceP;
              }
              this.villaDetail.platinum.gst =
                (this.villaDetail.platinum.total_price *
                  this.villaDetail.platinum.gst_tax) /
                100;

              //form
              this.form.id = this.villaDetail.platinum.villaTypeId;
              console.log("this.form.id platinum", this.form.id);
              this.form.booking.total = this.villaDetail.platinum.total_price;
              this.form.booking.gst_tax = this.villaDetail.platinum.gst_tax;
              this.form.booking.extra_person_charge = this.villaDetail.platinum
                .extra_total_charge
                ? this.villaDetail.platinum.extra_total_charge
                : 0;
              this.form.booking.festival_charge = this.villaDetail.platinum
                .festivalChargeP
                ? this.villaDetail.platinum.festivalChargeP
                : 0;
              this.form.booking.gst_tax_percent = this.villaDetail.platinum.gst;
            } else if (
              this.villaList[x].villa_name.toLowerCase() == "diamond villa"
            ) {
              this.villaDetail.diamond = this.villaList[x];
              var weekendPriceD = this.villaDetail.diamond.weekend
                ? this.villaDetail.diamond.weekend_price
                : 0;
              var festivalChargeD = this.villaDetail.diamond.festivalCharge
                ? this.villaDetail.diamond.festivalCharge
                : 0;
              if (
                this.form.total_adult > this.villaDetail.diamond.allow_adult
              ) {
                var total_d_extra_person =
                  this.form.total_adult - this.villaDetail.diamond.allow_adult; // 8-6=2
                this.villaDetail.diamond.extra_total_charge =
                  total_d_extra_person *
                  this.villaDetail.diamond.extra_per_person_charge;
                this.villaDetail.diamond.total_price =
                  this.villaDetail.diamond.price * this.form.qty +
                  this.villaDetail.diamond.extra_total_charge +
                  festivalChargeD +
                  weekendPriceD;
              } else {
                this.villaDetail.diamond.total_price =
                  this.villaDetail.diamond.price * this.form.qty +
                  festivalChargeD +
                  weekendPriceD;
              }
              this.villaDetail.diamond.gst =
                (this.villaDetail.diamond.total_price *
                  this.villaDetail.diamond.gst_tax) /
                100;

              //form
              this.form.id = this.villaDetail.diamond.villaTypeId;
              console.log("this.form.id diamond", this.form.id);
              this.form.booking.total = this.villaDetail.diamond.total_price;
              this.form.booking.gst_tax = this.villaDetail.diamond.gst_tax;
              this.form.booking.extra_person_charge = this.villaDetail.diamond
                .extra_total_charge
                ? this.villaDetail.diamond.extra_total_charge
                : 0;
              this.form.booking.festival_charge = this.villaDetail.diamond
                .festivalChargeD
                ? this.villaDetail.diamond.festivalChargeD
                : 0;
              this.form.booking.gst_tax_percent = this.villaDetail.diamond.gst;
            } else if (
              this.villaList[x].villa_name.toLowerCase() == "cottages"
            ) {
              this.villaDetail.cottage = this.villaList[x];
              var weekendPriceC = this.villaDetail.cottage.weekend
                ? this.villaDetail.cottage.weekend_price
                : 0;
              var festivalChargeC = this.villaDetail.cottage.festivalCharge
                ? this.villaDetail.cottage.festivalCharge
                : 0;
              if (
                this.form.total_adult > this.villaDetail.cottage.allow_adult
              ) {
                var total_c_extra_person =
                  this.form.total_adult - this.villaDetail.cottage.allow_adult; // 8-6=2
                this.villaDetail.cottage.extra_total_charge =
                  total_c_extra_person *
                  this.villaDetail.cottage.extra_per_person_charge;
                this.villaDetail.cottage.total_price =
                  this.villaDetail.cottage.price * this.form.qty +
                  this.villaDetail.cottage.extra_total_charge +
                  festivalChargeC +
                  weekendPriceC;
              } else {
                this.villaDetail.cottage.total_price =
                  this.villaDetail.cottage.price * this.form.qty +
                  festivalChargeC +
                  weekendPriceC;
              }
              this.villaDetail.cottage.gst =
                (this.villaDetail.cottage.total_price *
                  this.villaDetail.cottage.gst_tax) /
                100;

              //form
              this.form.id = this.villaDetail.cottage.villaTypeId;
              console.log("this.form.id cottage", this.form.id);
              this.form.booking.total = this.villaDetail.cottage.total_price;
              this.form.booking.gst_tax = this.villaDetail.cottage.gst_tax;
              this.form.booking.extra_person_charge = this.villaDetail.cottage
                .extra_total_charge
                ? this.villaDetail.cottage.extra_total_charge
                : 0;
              this.form.booking.festival_charge = this.villaDetail.cottage
                .festivalChargeC
                ? this.villaDetail.cottage.festivalChargeC
                : 0;
              this.form.booking.gst_tax_percent = this.villaDetail.cottage.gst;
            }
          }
        })
        .catch((e) => {
          console.log("e: ", e);
        });
    },
    ...mapActions(["addPlatinum"]),
    reserveVilla() {
      if (!this.token) {
        localStorage.setItem("id", this.form.id);
        localStorage.setItem("check_in_date", this.form.check_in_date);
        localStorage.setItem("check_out_date", this.form.check_out_date);
        localStorage.setItem("total_adult", this.form.total_adult);
        localStorage.setItem("total_child", this.form.total_child);
        localStorage.setItem("qty", this.form.qty);
        // this.getVillaDetail();
        localStorage.setItem("gst_tax", this.form.booking.gst_tax);
        localStorage.setItem("total", this.form.booking.total);
        localStorage.setItem(
          "extra_person_charge",
          this.form.booking.extra_person_charge
        );
        localStorage.setItem(
          "festival_charge",
          this.form.booking.festival_charge
        );
        localStorage.setItem(
          "gst_tax_percent",
          this.form.booking.gst_tax_percent
        );

        if (
          this.form.check_in_date.length === 0 ||
          this.form.check_out_date.length === 0
        ) {
          console.log("if");
          this.$toast.error("You need to select date");
        } else if (this.form.total_adult == 0) {
          console.log("if1");
          this.$toast.error("You need to add adult");
        } else if (this.form.qty == 0) {
          console.log("if2");
          this.$toast.error("You need to add villa qty");
        } else {
          console.log("else");
          this.popupMobile = !this.popupMobile;
        }
      } else {
        this.addPlatinum(this.form);
        let rawData = this.allPlatinums;
        if (isProxy(this.allPlatinums)) {
          rawData = toRaw(this.allPlatinums);
        }
        if (rawData[0].id) {
          this.$router.push({ name: "dashboard" });
        }
      }
    },
    daysSinceByDate() {
      const currentDate = new Date(this.form.check_out_date);
      const previousDate = new Date(this.form.check_in_date);
      const timeDifference = currentDate.getTime() - previousDate.getTime();
      this.dayCount = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      // return daysDifference;
    },
  },
  computed: mapGetters(["allPlatinums"]),
};
</script>

<style scoped>
.main_qun__ul {
  width: 50%;
}

.b_booking_feture {
  display: flex;
}

.font_bold {
  font-weight: 600;
}

@media all and (min-width: 320px) and (max-width: 767px) {
  /* villa list */
  .b_booking_nav_img img {
    height: 60px;
  }
  .main_qun_li {
    font-size: 12px;
  }
  img.main_qun_img {
    width: 22px;
    height: 18px;
    object-fit: contain;
  }
  .b_booking_feture li.main_qun_li {
    width: 50%;
    float: left;
  }
  .main_qun__ul {
    width: 100%;
  }
  /* end villa */
}

@media all and (min-width: 768px) and (max-width: 980px) {
  /* villa list */
  .b_booking_nav_img img {
    height: 60px;
  }
  .main_qun_li {
    font-size: 12px;
  }
  img.main_qun_img {
    width: 22px;
    height: 18px;
    object-fit: contain;
  }
  .b_booking_feture li.main_qun_li {
    width: 50%;
    float: left;
  }
  .main_qun__ul {
    width: 100%;
  }
  /* end villa */
}

@media only screen and (min-width: 981px) and (max-width: 1024px) {
  /* villa list */
  .b_booking_nav_img img {
    height: 60px;
  }
  .main_qun_li {
    font-size: 12px;
  }
  img.main_qun_img {
    width: 22px;
    height: 18px;
    object-fit: contain;
  }
  /* end villa */
}

@media all and (min-width: 1025px) and (max-width: 1140px) {
  /* villa list */
  .b_booking_nav_img img {
    height: 60px;
  }
  .main_qun_li {
    font-size: 12px;
  }
  img.main_qun_img {
    width: 22px;
    height: 18px;
    object-fit: contain;
  }
  /* end villa */
}

@media all and (min-width: 1141px) and (max-width: 1280px) {
  /* villa list */
  .b_booking_nav_img img {
    height: 60px;
  }
  .main_qun_li {
    font-size: 12px;
  }
  img.main_qun_img {
    width: 22px;
    height: 18px;
    object-fit: contain;
  }
  /* end villa */
}

@media all and (min-width: 1281px) and (max-width: 1440px) {
  /* villa list */
  .b_booking_nav_img img {
    height: 60px;
  }
  .main_qun_li {
    font-size: 12px;
  }
  img.main_qun_img {
    width: 22px;
    height: 18px;
    object-fit: contain;
  }
  /* end villa */
}

@media all and (min-width: 1441px) and (max-width: 1680px) {
}

@media all and (min-width: 1681px) and (max-width: 1880px) {
}
</style>
